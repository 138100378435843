import Vue from 'vue';
import Vuex from 'vuex';
import jwtDecode from 'jwt-decode';

const EMVI_PRODUCTS = ['emvi_contractor', 'emvi_client'];
const QFACT_PRODUCTS = ['crow_contractor', 'crow_client'];
const CROW_HYBRID = 'crow_hybrid';

Vue.use(Vuex);

export const storeObjects = {
    state: {
        notifications: [],
        jwt: localStorage.getItem('jwt') || '',
        refreshToken: localStorage.getItem('refreshToken') || '',
        twofactorJwt: localStorage.getItem('twofactorJwt') || '',
        twofactorQrCode: localStorage.getItem('twofactorQrCode') || '',
        user: JSON.parse(localStorage.getItem('user')) || {},
        currentOrganisation: JSON.parse(localStorage.getItem('currentOrganisation')) || {},
        requestedOrganisations: [],
        bodyListeners: [],
        createProjectTitle: '',
        hasActiveQfactProjects: JSON.parse(localStorage.getItem('hasQfactProjects')) || false,
        profileNotifications: localStorage.getItem('profileNotifications') || 0,
        lastRoutingEvent: {}
    },
    mutations: {
        notify(state, val) {
            const notif = {
                ...val,
                id: Math.floor(Math.random() * 1000),
                created: Date.now()
            };
            if (!notif.message) return;
            state.notifications.push(notif);
        },
        removeNotif(state, val) {
            state.notifications = state.notifications.filter(notif => notif.id !== val);
        },
        resetNotifs(state) {
            state.notifications = [];
        },
        setJwt(state, val) {
            state.jwt = val;
            localStorage.setItem('jwt', val);
        },
        setRefreshToken(state, val) {
            state.refreshToken = val;
            localStorage.setItem('refreshToken', val);
        },
        clearJwt(state) {
            state.jwt = '';
            localStorage.removeItem('jwt');
        },
        clearRefreshToken(state) {
            state.refreshToken = '';
            localStorage.removeItem('refreshToken');
        },
        setTwofactorJwt(state, val) {
            state.twofactorJwt = val;
            localStorage.setItem('twofactorJwt', val);
        },
        clearTwofactorJwt(state) {
            state.twofactorJwt = '';
        },
        setTwofactorQrCode(state, val) {
            state.twofactorQrCode = val;
            localStorage.setItem('twofactorQrCode', val);
        },
        setUser(state, val) {
            state.user = val;
            localStorage.setItem('user', JSON.stringify(val));
        },
        setCurrentOrganisation(state, val) {
            state.currentOrganisation = val;
            localStorage.setItem('currentOrganisation', JSON.stringify(val));
        },
        setRequestedOrganisations(state, val) {
            state.requestedOrganisations = val;
        },
        resetState(state, val) {
            localStorage.clear();
            state.notifications = [];
            state.jwt = '';
            state.refreshToken = '';
            state.twofactorJwt = '';
            state.twofactorQrCode = '';
            state.user = {};
            state.requestedOrganisations = [];
        },
        addBodyListener(state, bodyListener) {
            setTimeout(() => state.bodyListeners.push(bodyListener), 1);
        },
        resetBodyListeners(state) {
            state.bodyListeners = [];
        },
        setCreateProjectTitle(state, val) {
            state.createProjectTitle = val;
        },
        setHasActiveQfactProjects(state, val) {
            localStorage.setItem('hasQfactProjects', JSON.stringify(val));
            state.hasActiveQfactProjects = val;
        },
        setLastRoutingEvent(state, { from, to }) {
            state.lastRoutingEvent = {
                from,
                to
            };
        },
        setProjectConfiguration(state, val) {
            const userId = state.user.id;
            if(!userId) return
            
            state.currentOrganisation.settings.projectConfigurations[`configuration-${userId}`] = val;
            localStorage.setItem('currentOrganisation', JSON.stringify(state.currentOrganisation));
        }
    },
    getters: {
        getNotifs: state => state.notifications,
        isAuthenticated: state => !!state.jwt,
        getTwofactorQrCode: state => state.twofactorQrCode,
        getTwofactorJwt: state => state.twofactorJwt,
        getJwt: state => state.jwt,
        getJwtPayload: state => jwtDecode(state.jwt),
        getRefreshToken: state => state.refreshToken,
        getUser: state => state.user,
        getOrganisationRole: state => {
            const currentOrganisation = state.currentOrganisation
            const organisationUser = currentOrganisation.users.find(user => user.userId === state.user.id)
            return organisationUser ? organisationUser.roleId : 'user-default'
        },
        getCurrentOrganisation: state => state.currentOrganisation,
        getActiveProducts: state => {
            if (!state.currentOrganisation.products) return [];
            
            return state.currentOrganisation.products.filter(product => product.enabled)
        },
        hasActiveQfactProducts: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const activeProducts = orgProducts.filter(product => product.enabled);
            const qfactProducts = activeProducts.filter(product => QFACT_PRODUCTS.includes(product.slug));
            return !!qfactProducts.length;
        },
        hasActiveHybridMode: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const activeProducts = orgProducts.filter(product => product.enabled);
            const hybridProducts = activeProducts.filter(product => product.slug === CROW_HYBRID);
            return !!hybridProducts.length;
        },
        hasActiveQfactProjects: state => state.hasActiveQfactProjects,
        hasActiveEmviProducts: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const activeProducts = orgProducts.filter(product => product.enabled);
            const emviProducts = activeProducts.filter(product => EMVI_PRODUCTS.includes(product.slug));
            const tokenPayload = jwtDecode(state.jwt);
            return !!emviProducts.length || !!tokenPayload.data.authMeta.emviOrganisationId;
        },
        getOrganisationType: state => {
            const hasOrganisationToken = Object.keys(state.currentOrganisation).length > 0;

            const products = hasOrganisationToken
                ? state.currentOrganisation.products.filter(product => product.enabled).map(product => product.slug)
                : [];

            return products.length > 0 ? (products.join('_').includes('contractor') ? 'contractor' : 'client') : null;
        },
        getBodyListeners: state => state.bodyListeners,
        // TODO remove from store ??
        getRequestedOrganisations: state => state.requestedOrganisations,
        getCreateProjectTitle: state => state.createProjectTitle,
        hasPDProduct: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const hasPDProduct = orgProducts.filter(product => product.slug === 'pd' && product.enabled).length === 1

            return hasPDProduct
        },
        hasPDBasisProduct: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const hasPDProduct = orgProducts.filter(product => product.slug === 'pd_basis' && product.enabled).length === 1

            return hasPDProduct
        },
        hasPDFullProduct: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const hasPDProduct = orgProducts.filter(product => product.slug === 'pd_full' && product.enabled).length === 1

            return hasPDProduct
        },
        hasQfactPD: (state, getters) => {
            return getters.hasPDBasisProduct || getters.hasPDFullProduct
        },
        hasCrowReportFullProduct: state => {
            const orgProducts = state.currentOrganisation.products || [];
            const hasCrowReportProduct = orgProducts.filter(product => product.slug === 'crow_report_full' && product.enabled).length === 1
            
            return hasCrowReportProduct;
        },
        getLastRoutingEvent: state => {
            return state.lastRoutingEvent
        },
        getProjectConfiguration: state => {
            const userId = state.user.id;
            if(!userId) return

            const projectConfigurations = state.currentOrganisation?.settings?.projectConfigurations || {};
            return projectConfigurations[`configuration-${userId}`];
        }
    }
};

/**
 * Bodylisteners are methods called every time the user clicks.
 *
 * commit a method to the store with 'addBodyListener',
 * the next time the user clicks this method is executed and the listeners are reset.
 */
document.addEventListener('click', clickEvent => {
    const bodyListeners = store.getters.getBodyListeners || [];
    if (bodyListeners.length > 0) {
        bodyListeners.forEach(([excludeElement, callback]) => {
            const excluded = document.getElementById(excludeElement);
            let targetElement = clickEvent.target;

            do {
                if (targetElement == excluded) {
                    // This is a click inside. Do nothing, just return.
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);

            callback();
            store.commit('resetBodyListeners');
        });
    }
});

export const store = new Vuex.Store(storeObjects);
